import { BaseRoutes, Routes, StepRoutes } from './baseRoutes';
import navigateToUrl from './microfrontends/shared/utilities/navigateToUrl';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(prefix => location.href.indexOf(`${location.origin}${prefix}`) !== -1);

const mfNavbar = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.MARKETPLACE + Routes.WELCOME,
    BaseRoutes.MARKETPLACE + Routes.NOT_AFFILIATED,
    BaseRoutes.MARKETPLACE + Routes.SOLICIT,
    BaseRoutes.MARKETPLACE + Routes.CONTRACT,
    BaseRoutes.MARKETPLACE + Routes.DETAILS,
    BaseRoutes.MARKETPLACE + Routes.SHIPPING_DETAILS,
    BaseRoutes.MARKETPLACE + Routes.HISTORY_CHECK,
  );

const mfStepper = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.PERSONAL_INFO,
    BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.ADDRESS,
    BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.OCUPATION,
    BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.NIP,
    BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.IDENTITY,
    BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.DOCUMENTS,
  );

const mfWelcome = (location: Location): boolean => prefix(location, BaseRoutes.MARKETPLACE + Routes.WELCOME);

const mfNotAffiliated = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.NOT_AFFILIATED);

const mfPerson = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.PERSONAL_INFO);

const mfAddress = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.ADDRESS);

const mfOcupation = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.OCUPATION);

const mfNip = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.NIP);

const mfIdentity = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.IDENTITY);

const mfDocuments = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.DOCUMENTS);

const mfFinish = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.FINISH);

const mfConfirmation = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SOLICIT + StepRoutes.CONFIRM);

const mfContractSign = (location: Location): boolean => prefix(location, BaseRoutes.MARKETPLACE + Routes.CONTRACT);

const mfShippingDetails = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.SHIPPING_DETAILS);

const mfConsultaHistorialCredito = (location: Location): boolean =>
  prefix(location, BaseRoutes.MARKETPLACE + Routes.HISTORY_CHECK);

const app = (location: Location): boolean => {
  if (
    location.pathname === BaseRoutes.MARKETPLACE ||
    location.pathname === BaseRoutes.MARKETPLACE + Routes.SOLICIT ||
    location.pathname === BaseRoutes.MARKETPLACE.substr(0, BaseRoutes.MARKETPLACE.length - 1) ||
    location.pathname === '/'
  )
    navigateToUrl(`${BaseRoutes.MARKETPLACE + Routes.WELCOME}` || '');
  return true;
};

export default {
  app,
  mfNavbar,
  mfStepper,
  mfWelcome,
  mfNotAffiliated,
  mfPerson,
  mfAddress,
  mfOcupation,
  mfNip,
  mfIdentity,
  mfDocuments,
  mfFinish,
  mfConfirmation,
  mfContractSign,
  mfShippingDetails,
  mfConsultaHistorialCredito,
};
