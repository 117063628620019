export enum BaseRoutes {
  MARKETPLACE = '/app/store/',
}

export enum Routes {
  WELCOME = 'bienvenido',
  SOLICIT = 'solicitud',
  CONTRACT = 'contrato',
  DETAILS = 'detalles',
  NOT_AFFILIATED = 'no-asociado',
  SHIPPING_DETAILS = 'envio',
  HISTORY_CHECK = 'consulta-historial-crediticio',
}

export enum StepRoutes {
  PERSONAL_INFO = '/sobre-ti',
  ADDRESS = '/domicilio',
  OCUPATION = '/ocupacion',
  NIP = '/historial',
  IDENTITY = '/identificacion',
  DOCUMENTS = '/documentacion',
  CONFIRM = '/confirmar',
  FINISH = '/finalizado',
}
